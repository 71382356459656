.top-box {
  display: flex;
  /* align-items: center; */
  background-color: #ffc54d;
  height: 5.125rem;
  font-family: "Poppins", sans-serif;
  margin-top: 2rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
}

.policy-header {
  font-size: 1.5rem;
  font-weight: 800;
}

.policy-content {
  font-size: 1.2rem;
  font-weight: 600;
}

.inner-text {
  display: flex;
  justify-content: space-between;
  padding-left: 0.75rem;
  height: auto;
  padding-right: 1rem;
}

.flex-1-1 {
  flex: 1 1;
  max-width: 20%;
}

.flex-2-1 {
  flex: 2 1;
  max-width: 40%;
}

.detail-box {
  margin-bottom: 0.75rem;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  background-color: #f2f2f2;
  height: auto;
  padding: 0.75rem 1rem;
}

.policy-divider {
  height: 95%;
  width: 0.125rem;
  align-self: center;
  background-color: rgba(29, 29, 29, 0.3);
}

.ht-8 {
  height: 8rem;
}
.link {
  color: #006ce4;
}

.text-messages {
  max-width: 95%;
  word-wrap: break-word;
  overflow: hidden;
}

@media screen and (max-width: 34rem) {
  .policy-content {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .policy-header {
    font-size: 1.1rem;
    font-weight: 800;
  }
  .detail-box {
    padding: 0.8rem 0;
  }
  .top-box {
    padding: 0.8rem 0;
  }
  .inner-text {
    padding-left: 0.5rem;
    padding-right: 0.2rem;
  }
  .policy-divider {
    margin-left: 0.5rem;
  }
}
