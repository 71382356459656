.back-ground {
  padding-bottom: 2rem;
  background-repeat: repeat;
  background-color: var(--secondary-color);
}

.header-news-image {
  min-height: 70vh;
  max-height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.title {
  position: absolute;
  margin-top: 2rem;
  color: black;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 96px;
  line-height: 96px;
  font-style: normal;
}

.news-heading {
  margin-left: 45px;
  margin-top: 12rem;
  font-size: 60px;
  padding-top: 3rem;
  padding-left: 1.2rem;
  padding-bottom: 1.5rem;
  font-family: roboto;
}

.news-image {
  width: 25rem;
  aspect-ratio: 8/5;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  margin-left: 4rem;
  margin-bottom: 3rem;
}

.news-timings {
  color: grey;
  margin-top: 60px;
  font-size: 18px;
}

.news {
  display: flex;
  display: "inline-flex";
  margin-bottom: 5px;
  margin-left: 3px;
}

.body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  padding-right: 1.6rem;
}

@media screen and (max-width: 34rem) {
  .news {
    align-items: center;
  }
  .title {
    font-weight: 500;
    margin-top: 1rem;
    font-size: 45px;
    font-style: normal;
    color: #4f4f4f;
  }
  .news-heading {
    margin-left: 0.8rem;
    margin-top: 8rem;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    padding-top: 1.7rem;
    font-family: roboto;
  }
  .news-image {
    width: 20rem;
    border-radius: 15px;
    margin-left: 1.7rem;
    margin-bottom: 1rem;
  }
  .news {
    display: block;
    margin-right: 5rem;
  }
  .news-timings {
    color: grey;
    margin-top: 0.1rem;
    font-size: 0.9rem;
    margin-bottom: 3rem;
  }
  .body {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    padding-right: 1.6rem;
  }
}
