.authentication-bg {
  position: relative;
  width: 100%;
  min-height: calc(100vh - var(--navbar-height) - 40px);
  display: flex;
  /* margin-top : 64px; */
  flex-direction: column;
  justify-content: flex-start;
  background: url("../../assets/image/client-auth-bg-trueco.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.authentication-card {
  padding: 0.75rem 2rem;
  margin: auto 15%;
  max-width: 412px;
}

.authentication-title {
  margin-bottom: 1rem;
  text-align: center;
}

.button-w100-mt21 {
  width: 100%;
  margin-top: 21px;
}

.authentication-p {
  margin-bottom: 10px;
  margin-top: 19px;
  text-align: center;
  color: var(--high-emphasis);
}

@media screen and (max-width: 576px) {
  .authentication-card {
    margin: auto 2%;
  }
}

.toast-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(41, 41, 41, 0.87) !important;
  color: white;
}

.toast-body-container {
  padding: 16px !important;
}
