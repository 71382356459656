.select-container { 
    position:relative;  
}

.floating-select {
    display:block;
    width:100%;
    height:100%;
    background-color: transparent;
    border:1px solid var(--medium-emphasis);
    border-radius: 5px;
    padding-top: 28px;
    padding-left: 24px;
    padding-right: 52px;
    padding-bottom: 12px;
}

.floating-select {
    -webkit-appearance: none;
    appearance: none;
}

.select-container::after {
    content: url(../../../assets/icon/arrow_down.svg);
    font-size: 1rem;
    top : 22px;
    right : 24px;
    margin: 0;
    padding: 0;
    position: absolute;
}

.floating-select:focus {
    outline:none;
    border: 1px solid var(--primary-color); 
}

.floating-label-select {
    position: absolute;
    bottom : 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    margin: 0 !important;
}

.floating-select:focus ~ .floating-label-select .floating-label-select-content , 
.floating-select:not([value=""]):valid ~ .floating-label-select .floating-label-select-content {
    transform: translateY(-0.5em);
    font-size:10px;
    line-height: 15px;
    color : var(--primary-color);
}

.floating-label-select-content {
    color:var(--medium-emphasis); 
    font-size:14px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    top : 17px;
    left : 24px;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease; 
    -moz-transition: all 0.3s ease; 
    -webkit-transition: all 0.3s ease;
}


*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

  /* active state */
.floating-select:focus ~  {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
}