@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Rubik:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Poppins", "Rubik", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --primary-color: #292929;
  --gray-1-color: #333333;
  --grey-2-color: #4f4f4f;
  --grey-3-color: #828282;
  --gray-5-color: #e0e0e0;
  --accent-color: #ffc54d;
  --secondary-color: #f2f2f2;
  --high-emphasis: rgba(41, 41, 41, 0.87);
  --medium-emphasis: rgba(41, 41, 41, 0.6);
  --low-emphasis: rgba(41, 41, 41, 0.38);
  --success-color: #3ebe61;
  --information-color: #006ce4;
  --warning-color: #ef9400;
  --error-color: #eb4e2c;
  --navbar-height: 64px;
}

/* utilities */

.montserrat-extra-bold-fs48-lh72 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 72px;
}

.montserrat-extra-bold-fs30-lh42 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 42px;
}

.montserrat-bold-fs24-lh29 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.montserrat-bold-fs18-lh24 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.montserrat-bold-fs16-lh20 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.montserrat-medium-fs24-lh29 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.montserrat-medium-fs18-lh20 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

.montserrat-medium-fs18-lh24 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.montserrat-medium-fs16-lh20 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.montserrat-medium-fs32-lh39 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
}

.montserrat-medium-fs22-lh28 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

.merriweather-black-fs20-lh36 {
  font-family: "Merriweather", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 36px;
}

.parisienne-normal-fs96-lh96 {
  font-family: "Parisienne", sans-serif;
  font-weight: 400;
  font-size: 96px;
  line-height: 96px;
  font-style: normal;
}

.parisienne-normal-fs80-lh96 {
  font-family: "Parisienne", sans-serif;
  font-weight: 400;
  font-size: 80px;
  line-height: 96px;
  font-style: normal;
}

.parisienne-normal-fs96-lh96-lw {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 80px;
  line-height: 86px;
  /* line-break: anywhere; */
  font-style: normal;
}

.parisienne-normal-fs80-lh96-lw {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  /* line-break: anywhere; */
  font-style: normal;
}

.parisienne-normal-fs72-lh80 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 72px;
  line-height: 80px;
  font-style: normal;
}

.parisienne-normal-fs40-lh80 {
  font-family: "Parisienne", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 80px;
  font-style: normal;
}

.parisienne-normal-fs24-lh40 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
}

.psb54-lh54 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 54px;
  line-height: 54px;
}

.pb36-lh56 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 56px;
}

.pb32-lh40 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.04em;
}

.pb24-lh40 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  font-style: normal;
}

.psb24-lh40 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  font-style: normal;
}

.pr24-lh40 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  font-style: normal;
}

.pb20-lh32 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  font-style: normal;
}

.pr20-lh32 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.pb18-lh32 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.pr18-lh32 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 32px;
  font-weight: normal;
  font-style: normal;
}

.pb16-lh24 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.pr16-lh24 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.pb14-lh21 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.pr14-lh21 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.pr14-lh24 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.pb12-lh18 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.pr12-lh18 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.pr12-lh24 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}

.pr12-lh16 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.pb12-lh16 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.pr10-lh15 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
}

.pb10-lh15 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
}

.rb24-lh40 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
}

.rubik-extra-bold-fs54-lh64 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 54px;
  line-height: 64px;
}

.rubik-extra-bold-fs32-lh40 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
}

.mr40-vertical {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

/* .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

.button {
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  color: #000;
  border: none;
  border-radius: 5px;
}

.button-primary {
  background: var(--primary-color);
  color: #fff;
}

.button-accent {
  background: var(--accent-color);
  color: var(--primary-color);
}

.button-outline {
  background-color: transparent;
  border: 1px #fff solid;
}

.p4-horizontal {
  padding: 0 4px;
}

.pt-cursor {
  cursor: pointer;
}

/* .flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
} */

/* Solid border */
hr.solid {
  border: none;
  height: 1px;
  background: var(--medium-emphasis);
}

.primary-color {
  color: var(--primary-color);
}

.information-color {
  color: var(--information-color);
}

.high-emphasis-color {
  color: var(--high-emphasis);
}

.medium-emphasis-color {
  color: var(--medium-emphasis);
}

.low-emphasis-color {
  color: var(--low-emphasis);
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-right-24 {
  padding-right: 24px !important;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
} */

.gray-1-color {
  color: var(--gray-1-color);
}

.grey-2-color {
  color: var(--grey-2-color);
}

.grey-3-color {
  color: var(--grey-3-color);
}

.success-color {
  color: var(--success-color);
}

.error-color {
  color: var(--error-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.secondary-color-bg {
  background-color: var(--secondary-color);
}

.accent-color-bg {
  background-color: var(--accent-color);
}

.low-emphasis-color-bg {
  background-color: var(--low-emphasis);
}

.success-color-bg {
  background-color: var(--success-color);
}

.success-color-bg-o75 {
  background-color: rgba(62, 190, 97, 0.75);
}

.success-color-bg-o50 {
  background-color: rgba(62, 190, 97, 0.5);
}

.error-color-bg {
  background-color: var(--error-color);
}

.margin-64 {
  margin-top: 64px;
}

.icon-width-18 {
  width: 18px;
}

.icon-width-24 {
  width: 24px;
}

.content-area {
  height: calc(100vh - 64px);
  width: 100%;
}

.truncate {
  max-width: 181px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  z-index: 0;
}

.masonry-grid_column {
  padding-right: 10px; /* gutter size */
  background-clip: padding-box;
}

@media only screen and (max-width: 599px) {
  .content-area {
    height: calc(100vh - 56px);
    width: 100%;
  }
}

.close-btn.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.fullWidth {
  width: 100%;
}

.page-content {
  max-width: 60%;
  margin: auto;
  min-height: calc(100vh - 20px); 
}

@media screen and (max-width: 768px) {
  .page-content {
    max-width: 80%;
  }
  
}
