.client-authentication-bg {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 104px);
  display: flex;
  margin: auto 0;
  flex-direction: column;
  justify-content: flex-start;
  background: url("../../assets/image/sign-in-trueco.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.client-authetication-card {
  padding: 0.75rem 2rem;
  margin: auto;
  max-width: 412px;
  width: 100%;
}

.sign-in-option-btn {
  border: 1px solid rgba(41, 41, 41, 0.6);
  border-radius: 4px;
  padding: 15px 0;
  display: flex;
}

.google-text {
  font-size: 20px;
  line-height: 23px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  margin: 0px 15px;
}

.facebook-container {
  background: #1877f2;
  border: #1877f2;
  border-radius: 4px;
  padding: 15px 40px;
  display: flex;
  margin-bottom: 20px;
}

.faceboook-text {
  font-size: 20px;
  line-height: 23px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  margin: 0px 15px;
}

@media screen and (max-width: 576px) {
  .client-authetication-card {
    margin: auto 2%;
  }

  .google-border {
    padding: 15px 20px;
  }

  .facebook-container {
    padding: 15px 12px;
  }

  .faceboook-text {
    margin: 0px 10px;
  }
}
