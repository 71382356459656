.input-box {
    width: 100%;
    height: 100%;
    padding-top: 28px;
    padding-left: 24px;
    padding-right: 12px;
    padding-bottom: 12px;
    color: var(--primary-color);
    border: 1px solid var(--medium-emphasis);
    border-radius: 4px;
}

.input-box:focus,
.input-box:hover {
    border-color: var(--primary-color);
    outline: none;
}

.input-box-container {
    position: relative;
}

.input-box-container.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.input-box::placeholder {
    color : transparent;
}

.floating-label {
    position: absolute;
    bottom : 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    margin: 0 !important;
}

.floating-label-content {
    position: absolute;
    bottom : 17px;
    left : 24px;
    margin: 0;
    padding: 0;
    color : var(--medium-emphasis);
    transition: all 0.3s ease;
}

form .input-box:focus + .floating-label .floating-label-content,
form .input-box:not(:placeholder-shown) + .floating-label .floating-label-content {
    transform: translateY(-2.3em);
    font-size: 10px;
    line-height: 15px;
    color: var(--primary-color);
}

.margin-bottom-16 {
    margin-bottom: 1rem;
}

.margin-left-5 {
    margin-left: 5px;
}

.error-floating-label-content {
    position: absolute;
    bottom : 17px;
    left : 24px;
    margin: 0;
    padding: 0;
    color : var(--medium-emphasis);
    transition: all 0.3s ease;
}

form .input-box:focus + .floating-label .error-floating-label-content,
form .input-box:not(:placeholder-shown) + .floating-label .error-floating-label-content {
    transform: translateY(-2.3em);
    font-size: 10px;
    line-height: 15px;
    color: var(--error-color);
}

.error {
    border: 1px solid var(--error-color);
}

.error:focus,
.error:hover {
    border: 1px solid var(--error-color);
}

.margin-bottom-12 {
    margin-bottom: 12px;
}

