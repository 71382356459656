.landing-page-bg {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 104px);
  display: flex;
  margin: auto 0;
  flex-direction: column;
  justify-content: flex-start;
  background: url("../../assets/image/landing-page-trueco.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}

.landing-page-container {
  /* max-width: 50%;
    margin-top: 22%;
    margin-left: 6%; */
  position: absolute;
  top: 20%;
  left: 6%;
}

.landing-page-container h1 {
  color: var(--secondary-color);
  font-size: 48px;
  font-weight: bold;
  line-height: 72px;
  margin-bottom: 10px;
}

.landing-page-container h2 {
  color: var(--secondary-color);
  font-weight: 300;
  font-size: 24px;
  line-height: 40px;
}

.landing-page-button {
  padding: 0.7em 3.57em;
  border: none;
  text-transform: uppercase;
  margin-top: 9%;
}

@media screen and (max-width: 400px) {
  .landing-page-container h1 {
    line-height: 1.2em;
    font-size: 2.5rem;
  }

  .landing-page-container h2 {
    line-height: 1.3em;
    font-size: 1.125rem;
  }

  .landing-page-button {
    font-size: 0.8rem !important;
  }
}
